.articlePageWrapper {
  max-width: 1600px;
  margin: 0 auto;
}
.articlesWrapper {
  width: 100%;
  padding-top: 120px;
}
.articleWrapper {
  margin: 0;
  padding-top: 120px;
  position: relative;
  background: linear-gradient(180deg, rgba(234,255,109,1) 0px, rgba(234,255,109,1) 900px, rgba(255,255,255,1) 1800px, rgba(255,255,255,1) 100%);
}
.articles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 24px;
  column-gap: 32px;
  // Bellow 900px make it one column
  @media (max-width: 900px) {
    flex-direction: column;
  }
  // Bellow 600px convert to simple column
  @media (max-width: 600px) {
    display: block;
  }
}
.mainArticle {
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
  h2.itemTitle {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  .articleItem {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    border-top: 0;
  }
}
.articlesContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  // Bellow 900px make it one column
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
  img.thumbnail {
    width: 241px;
    @media (max-width: 600px) {
      width: 84px;
    }
  }
  h2.itemTitle {
    padding: 0;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  .articleItem {
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
    display: flex;
    flex-direction: row;
    border-top: 1px solid black;
    padding-top: 32px;
    column-gap: 32px;
    @media (max-width: 600px) {
      align-items: center;
      column-gap: 16px;
      padding-top: 16px;
      border-top: 1px solid #D9D9D9;
    }
  }
}
.pageContentWrapper {
  display: flex;
  justify-content: center;
}
.pageContent {
  width: 100%;
  max-width: 1280px;
  padding: 0 24px;
}
.pageTitle {
  font-size: 48px;
  font-weight: 500;
  @media (max-width: 600px) {
    font-size: 32px;
  }
  padding: 48px 0 24px;
  // Bellow 900px reduce padding
  @media (max-width: 900px) {
    padding: 32px 0 24px;
  }
  @media (max-width: 600px) {
    padding: 24px 0 0;
  }
}
.itemTitle {
  font-weight: 500;
}
.thumbnail {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: 16px;
}
.articleHeaderSection {
  display: flex;
  justify-content: center;
  //background-color: var(--color-primary-layer-3);
  padding: 0 24px;
}
.articlesPageHeader {
  font-size: 80px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  @media (max-width: 900px) {
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media (max-width: 600px) {
    display: none;
  }
}
.articleHeaderContainer {
  width: 100%;
  max-width: 1000px;
  padding: 0px 0 72px;
  // Bellow 900px reduce padding
  @media (max-width: 900px) {
    padding: 48px 0 40px;
  }
  @media (max-width: 600px) {
    padding: 0 0 32px;
  }
}
.articleHeaderImage {
  img {
    width: calc(100vw - 48px);
    max-width: 1000px;
    border-radius: 16px;
  }
  margin: 0 0 128px;
  padding: 0;

  // Bellow 900px reduce margin
  @media (max-width: 900px) {
    margin: 0 0 64px;
  }
  @media (max-width: 600px) {
    margin: 0 0 32px;
  }
}
.category {
  font-size: 18px;
  text-align: center;
  margin-top: 80px;
  @media (max-width: 600px) {
    margin-top: 0;
  }
}
h1.articleTitle {
  text-align: center;
  max-width: 1000px;
  font-size: 80px;
  font-weight: bold;
  margin: 20px 0 64px;
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
}
.excerpt {
  width: calc(100vw - 48px);
  max-width: 784px;
  font-size: 24px;
  font-weight: bold;
  line-height: 150%;
  margin: 0 auto;
  padding-bottom: 64px;
  border-bottom: 1px solid black;
  @media (max-width: 600px) {
    padding-bottom: 32px;
  }
  p {
    line-height: 150%;

    @media (max-width: 900px) {
      margin-left: 24px;
      margin-right: 24px;
      font-size: 20px;
    }
    @media (max-width: 600px) {
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.articleContent {
  width: calc(100vw - 48px);
  max-width: 784px;
  font-size: 24px;
  font-weight: 500;
  p, h3,ol,ul {
    @media (max-width: 900px) {
      font-size: 20px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  h2 {
    font-size: 40px;
    margin: 72px 0 40px;
    font-weight: bold;
    @media (max-width: 900px) {
      font-size: 24px;
      margin: 48px 0 24px;
    }
    @media (max-width: 600px) {
      font-size: 24px;
      margin: 32px 0 16px;
    }
  }
  h3 {
    margin-top: 72px;
    margin-bottom: 24px;
    font-weight: bold;
    @media (max-width: 900px) {
      margin-top: 48px;
      margin-bottom: 16px;
    }
    @media (max-width: 600px) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
  p {
    line-height: 150%;
  }
  ul, ol {
    line-height: 150%;
  }
  li {
    line-height: 150%;
    margin-left: 0;
    padding: 0;
    p {
      margin-left: 8px;
      a {
        font-weight: 400;
        color: var(--primary-text-strong);
        text-decoration: underline;
      }
    }
  }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child {
    margin-top: 0;
  }
}
