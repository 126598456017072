.siteWrapper {
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-rows: min-content auto min-content;
}
.notFoundPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 40px;
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 0 0;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  border: none;
}
.logo {
  border: none;
}
.logo svg  {
  width: 106px;
  fill: black;
  shape-rendering: geometricPrecision;
}