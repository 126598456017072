//noinspection CssInvalidAtRule
@import-normalize;
@font-face {
  font-family: GT-Flexa;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/GT-Flexa-Standard-Regular.otf');
  src: url('fonts/GT-Flexa-Standard-Regular.otf') format('embedded-opentype'),
       url('fonts/GT-Flexa-Standard-Regular.woff2') format('woff2'),
       url('fonts/GT-Flexa-Standard-Regular.woff') format('woff'),
       url('fonts/GT-Flexa-Standard-Regular.ttf') format('truetype');
}
@font-face {
  font-family: GT-Flexa;
  font-style: normal;
  font-weight: bold;
  src: url('fonts/GT-Flexa-Standard-Bold.otf');
  src: url('fonts/GT-Flexa-Standard-Bold.otf') format('embedded-opentype'),
       url('fonts/GT-Flexa-Standard-Bold.woff2') format('woff2'),
       url('fonts/GT-Flexa-Standard-Bold.woff') format('woff'),
       url('fonts/GT-Flexa-Standard-Bold.ttf') format('truetype');
}

@import "./styles/colors.scss";
@import "./styles/variables.scss";
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "GT-Flexa", sans-serif;
  color: var(--primary-text-strong);
  background-color: var(--color-primary-layer-1);
  overflow-x: hidden;
}
*,
button,
div {
  font-family: "GT-Flexa", sans-serif;
  color: var(--primary-text-strong);
}
body,
button,
input,
select,
textarea {
  font-family: "GT-Flexa", serif;
  font-size: var(--font-size-default);
  line-height: var(--line-height-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fg-default);
  font-variant-numeric: tabular-nums;
}
a,
a:link,
a:visited,
a:active,
a:hover {
  color: var(--fg-default);
  text-decoration: none;
}
