@import '../../styles/variables.scss';
.footer {
  min-height: 200px;
  margin-top: 100px;
  padding-top: 256px;
  // Fade from white to green
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,255,109,1) 68%, rgba(234,255,109,1) 100%);
}
.footerWrapper {
  max-width: var(--desktop-max-width);
  margin-left: auto;
  margin-right: auto;
}
.footerContainer {
  font-size: 18px;
  font-weight: 400;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 32px;
  color: white;
  padding-top: 64px;
  padding-bottom: 64px;
  // switch to column bellow 600px
  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
  }
}
.footer {
  a, a:link, a:hover, a:active, a:visited {
    color: black;
    font-weight: 400;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease-in-out;
  }
  a:hover, a:active {
    border-bottom: 1px solid black;
  }
  a.logo:hover, a.logo:active {
    border-bottom: 1px solid transparent;
  }
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .logo svg {
    width: 106px;
    fill: black;
    shape-rendering: geometricPrecision;
  }
}