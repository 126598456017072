.sectionTitleWrapper {
  scroll-margin: 160px;
  height: min-content;
  width: fit-content;
  margin: 256px auto 128px;
  @media (max-width: 1400px) {
    margin-top: 150px;
    margin-bottom: 90px;
    font-size: 70px;
  }
  @media (max-width: 1200px) {
    margin-top: 150px;
    margin-bottom: 80px;
    font-size: 60px;
  }
  @media (max-width: 900px) {
    margin-top: 130px;
    margin-bottom: 70px;
    font-size: 50px;
    max-width: 700px;
  }
  @media (max-width: 600px) {
    margin-top: 100px;
    margin-bottom: 60px;
    font-size: 40px;
  }
  @media (max-width: 500px) {
    margin-top: 80px;
    font-size: 32px;
  }
}
.sectionTitleWrapper {
  opacity: 0;
  transform: rotate(30deg); /* Initial tilted position */
  transition: margin-top 1s, margin-bottom 1s, transform 1s; /* Transition for opacity and transform */
  // The anchor point of the rotation should be on the top left
  transform-origin: top left;
}
.sectionTitleWrapper.titleInView {
  opacity: 1;
  transform: rotate(0deg); /* Final non-tilted position */
}
.sectionTitle {
  max-width: var(--desktop-max-width);
  font-size: 80px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0;
  text-align: center;
  color: var(--primary-text-strong);
  font-weight: 600;
  // media queries for different screen sizes adjusting the font-size
  @media (max-width: 1400px) {
    font-size: 70px;
  }
  @media (max-width: 1200px) {
    font-size: 60px;
  }
  @media (max-width: 900px) {
    font-size: 50px;
  }
  @media (max-width: 600px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 32px;
  }
}
.sectionTitlePrefix {
  margin: 0 auto;
  font-size: 18px;
  line-height: 100%;
  padding: 10px;
  border-radius: 100px;
  background-color: var(--color-secondary-strong);
  width: fit-content;
  // Bellow 600 set font size to 12px
  @media (max-width: 600px) {
    font-size: 12px;
  }
}