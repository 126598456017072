.sectionWrapper {
  width: 100%;
  display: block;
  position: relative;
  //height: 600px;
  //max-height: 800px;
  background-color: var(--color-secondary-strong);
  margin-bottom: 100px;
}
.sectionWrapper::after {
  display: block;
  content: " ";
  height: 100%;
  max-height: 400px;
  width: 100%;
  // Background fade from green to white
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    var(--color-secondary-strong) 100%
  );
  position: absolute;
  top: 100%;
  z-index: -1;
  left: 0;
}
.sectionBackgroundWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sectionContainer {
  max-width: var(--desktop-max-width);
  display: flex;
  margin-top: 150px;
  margin-bottom: 50px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  // Mediaqueries adjusting the margings
  @media (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.sectionTitle {
  font-size: 80px;
  text-align: center;
  color: black;
  margin-block-end: 24px;
  // media queries for different screen sizes adjusting the font-size
  @media (max-width: 900px) {
    font-size: 60px;
  }
  @media (max-width: 600px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
  }
}
.sectionContent {
  font-size: 20px;
  font-weight: normal;
  max-width: 784px;
  text-align: center;
  line-height: 150%;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.strongSectionContent {
  font-weight: 600;
}
a.sectionButton {
  padding: 16px 24px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 62px;
  color: var(--primary-text-faint);
}
