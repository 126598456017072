.testimonialImage {
  img {
    height: 24px;
  }
}
.testimonialTitle {
  padding-bottom: 24px;
  margin: 0 auto;
  text-align: center;
}
.testimonialGroupWrapper {
  //background: linear-gradient(180deg, #EAFF6D 0%, #FFFFFF 100%);
  background-color: var(--color-secondary-strong);
}
.testimonialContainerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 80px;
  // Bellow 900 px switch to column
  @media (max-width: 900px) {
    row-gap: 32px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 32px;
  }
}