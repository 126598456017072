.cardWrapper {
  background-color: var(--color-base-1);
  //border: 1px solid #d9e0e4;
  width: 100%;
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  // Bellow 900px switch to column
  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 0px;
  }
}
.cardWrapper:nth-child(2n) {
  flex-direction: row-reverse;
    // Bellow 900px switch to column
  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 0px;
  }
}
.cardContainer {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 96px 64px 96px 64px;
  // media queries for different screen sizes adjusting the padding
  @media (max-width: 900px) {
    padding: 64px 32px 0px 32px;
    width: initial;
  }
}
.cardHeader {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.title {
  font-size: 40px;
  font-weight: 600;
  color: var(--color-accent-1);
  // media queries for different screen sizes adjusting the font-size
  //@media (max-width: 1400px) {
  //  font-size: 20px;
  //}
  @media (max-width: 600px) {
    font-size: 24px;
  }
}
.content {
  font-size: 18px;
  font-weight: 400;
  color: white;
  //@media (max-width: 1400px) {
  //  font-size: 14px;
  //}
  @media (max-width: 600px) {
    font-size: 18px;
  }
}
.cardFigure {
  display: flex;
  align-self: flex-end;
  width: 50%;
  //padding: 115px 0 0 64px;

  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 8px;
  svg {
    display: block;
    //height: 250px;
  }
  //@media (max-width: 1400px) {
  //  svg {
  //    width: 50%;
  //  }
  //}
  // media queries for different screen sizes adjusting the width
  @media (max-width: 900px) {
    width: 100%;
  }
}
.cardWrapper:nth-child(2n) .cardFigure {
  //align-self: flex-start;
}
.shadow {
  box-shadow: var(--card-box-shadow);
}