.docsSectionWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 600px) {
    row-gap: 24px;
  }
}
.docsSectionItemWrapper {
  padding-top: 32px;
  border-top: 1px solid #D9D9D9;
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  // switch to column bellow 600px
  @media (max-width: 900px) {
    column-gap: 24px;
  }
  @media (max-width: 600px) {
    column-gap: 16px;
    padding-top: 32px;
  }
}
.image svg {
  width: 166px;
  //margin-left: -16px;
  // scale down after 1400, 900 and 600
  @media (max-width: 900px) {
    width: 120px;
  }
  @media (max-width: 600px) {
    width: 80px;
  }
}
.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;

  // scale down after 1400, 900 and 600
  @media (max-width: 900px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }

}
.body {
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}
