:root {
  --large-max-width: 1800px;
  --desktop-max-width: 1328px;
  --card-box-shadow: 0px 1px 14px rgba(191, 223, 255, 0.12),
    0px 3px 5px rgba(53, 78, 102, 0.2);
  --card-border-radius: 16px;
  @media (max-width: 1400px) {
    --desktop-max-width: 1000px;
  }
  //@media (max-width: 1200px) {
  //  --desktop-max-width: 900px;
  //}
  //@media (max-width: 1000px) {
  //  --desktop-max-width: 800px;
  //}
  //@media (max-width: 900px) {
  //  --desktop-max-width: 600px;
  //}
  // Mediaqueries determining the border radius variable starting at 16px going down to 8px
  @media (max-width: 900px) {
    --card-border-radius: 16px;
  }
  @media (max-width: 600px) {
    --card-border-radius: 12px;
  }
  @media (max-width: 500px) {
    --card-border-radius: 8px;
  }
}
