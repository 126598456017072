.sectionTitleHighlight {
  position: relative;
}
.sectionTitleHighlight::after {
  z-index: -1;
  content: " ";
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: var(--color-secondary-strong);
  filter: blur(20px);
}