.floatyMenuWrapper {
  position: sticky;
  z-index: 10;
  top: 40px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  background-color: var(--color-secondary-strong);
  height: 69px;
  border-radius: 69px;
  padding: 0 32px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 600px) {
    height: 48px;
    position: fixed;
    top: auto;
    bottom: 24px;
    left: 16px;
    right: 16px;
    margin: 0 auto;
    //top: auto;
    overflow-x: scroll;
    justify-content: flex-start;
  }
}
.floatyMenuContainer {
  display: flex;
  align-items: center;
  z-index: 10;
  column-gap: 32px;
  height: 69px;
  @media (max-width: 600px) {
    font-size: 20px;
    background-color: var(--color-secondary-strong);
    flex-direction: column;
    justify-content: center;
    row-gap: 32px;
    position: fixed;
    z-index: -1;
    transform: translateY(-100%);
    transition: transform 0.2s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}
.expanded .floatyMenuContainer {
  @media (max-width: 600px) {
    transform: translateY(0);
  }
}
.floatyMenuToggle {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 18px;
  height: 48px;
  // hide if more than 600px with
  @media (min-width: 600px) {
    height: 48px;
    display: none;
  }
}
.menuItemWrapper {
  display: flex;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  transition: border 0.2s ease-in-out;
  &:hover {
    //transform: scale(1.1);
    border-bottom: 1px solid black;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    font-weight: bold;
  }
}
a.inverted {
  background-color: black;
  border: 1px solid black;
  color: var(--color-secondary-strong);
  padding: 8px 16px;
  border-radius: 36px;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:hover {
    //transform: scale(1.1);
    background-color: var(--color-secondary-strong);
    color: black;
  }
}